<template>
  <div class="main-page">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row ">
        <el-form-item label="课程名称：" label-width="70px">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.courseName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="课程种类：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.courseTypeArr">
            <el-checkbox class="zwx-checkbox" :label="1">标准课程</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">自定义课程</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="课程状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableArr">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="query()">查询</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid+'-only'" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="courseName" label="课程名称" min-width="300" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="courseType" label="课程种类" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.courseType == 1 ? '标准课程' : '自定义课程' }}
        </template>
      </el-table-column>
      <el-table-column prop="traineeTypeName" label="课程分类" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.traineeTypeName }}
        </template>
      </el-table-column>
      <el-table-column prop="trainingTypeName" label="课程类型" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.trainingTypeName }}
        </template>
      </el-table-column>
      <el-table-column prop="requiredHour" label="必要学时" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.requiredClassHour }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="requiredHour" label="线上课程学时要求" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.onlineRequiredClassHoure }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="requiredHour" label="线下课程学时要求" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.offLineTotalClassHour }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="ifEnable" label="课程状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-normal">启用</div>
          </template>
          <template v-if="!scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-underway">停用</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="courseName" label="创建时间" width="170" header-align="center" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="200" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" v-if="scope.row.ifEnable === true" type="text" @click="copyAdd(scope.row)">复制</el-button>
          <el-button v-if="scope.row.courseType != 1 && scope.row.ifEnable === false" class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="editTrainingCourse(scope.row)">编辑</el-button>
          <el-button v-if="scope.row.courseType != 1 && scope.row.ifEnable === false" class="zwx-button zwx-button-text-26" type="text" @click="enableOrDisable(scope.row)">启用</el-button>
          <el-button v-if="scope.row.courseType != 1 && scope.row.ifEnable === true" class="zwx-button zwx-button-text-26" type="danger" @click="enableOrDisable(scope.row)">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" v-if="scope.row.ifEnable === false" type="text" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteTrainingCourse)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
  </div>
</template>

<script>
export default {
  name: 'WxCourseManage',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        courseName: '',
        courseTypeArr: [],
        ifEnableArr: [1],
      },
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
      ifShowOff: true,
    }
  },
  created() {
    this.ifShowOff = window.top.vm.$store.state.config.ifTrainingClassOffline
  },
  mounted() {
    this.query()
  },
  activated() {
    if (this.$route.params.fresh) this.currentChange(this.currentPage)
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 查询
     */
    query() {
      this.currentPage = 1
      this.currentChange(1)
    },
    /**
     * 分页查询
     */
    currentChange(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        courseName: this.msForm.courseName,
        courseTypeArr: this.msForm.courseTypeArr,
        ifEnableArr: this.msForm.ifEnableArr,
        currentPage: currentPage,
      }
      this.$system.postJson(
        this.api + '/training/platform/getTrainingCourseList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.dataList = data.trainingCourseList
            this.total = data.trainingCourseCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 启用/停用
     */
    enableOrDisable(row) {
      var mess = row.ifEnable ? '停用' : '启用'
      this.$system.msgbox('', '提示', '是否确定' + mess + '？', '确定', '取消', () => {
        let data = {
          uuid: row.uuid,
          ifEnable: row.ifEnable ? false : true,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/training/platform/enableOrDisableTrainingCourseByCourseUuid-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type == '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.currentChange(this.currentPage)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },

    /**
     * 删除
     */
    deleteTrainingCourse(row) {
      let data = {
        uuid: row.uuid,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/deleteTrainingCourse-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.query()
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //添加
    copyAdd(row) {
      this.$router.push({ name: 'WxCourseManageEdit', params: { courseUid: row.uuid, type: 'add', editType: 1 } })
    },
    //编辑
    editTrainingCourse(row) {
      let data = {
        courseUid: row.uuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getCourseIsInUse-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            if (data.isInUse) {
              this.$system.notify('提示', '该课程已有报名记录，请复制新增课程', 'info')
            } else {
              this.$router.push({ name: 'WxCourseManageEdit', params: { courseUid: row.uuid, type: 'edit', editType: 2 } })
            }
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
  },
}
</script>
<style lang="less" scoped></style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 300px;
}
</style>
