<template>
  <div class="harzard-declaration-list-page">
    <el-form class="zwx-form" key="hazardDeclarationMainForm" :model="hazardDeclarationMainForm" ref="hazardDeclarationMainForm" label-position="right" @submit.native.prevent>
      <div class="condition-row self-adapting-condition">
        <el-form-item label="申报年份：">
          <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="hazardDeclarationMainForm.declareYear" placeholder="" :filterable="true" :clearable="true" @change="declareYearChange">
            <el-option v-for="item in declarYearList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <div class="condition-button-row">
          <!-- <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addHazardDeclaration" :disabled="ifAddHazardDeclaration === false">添加</el-button> -->
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addHazardDeclaration">添加</el-button>
        </div>
      </div>
    </el-form>
    <el-table class="zwx-table" style="width: 100%" row-key="rid" key="hazardDeclarationMainTable" :data="tableList" stripe border>
      <el-table-column prop="declareYear" label="年份" width="120" header-align="center" align="center"></el-table-column>
      <el-table-column prop="declareDate" label="申报日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
      <!--<el-table-column prop="informantDate" label="填报日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>-->
      <el-table-column prop="declareType" label="申报类型" width="150" header-align="center" align="center" :formatter="declareTypeFormatter"></el-table-column>
      <el-table-column prop="informant" label="填报人" width="120" header-align="center" align="center"></el-table-column>
      <!--<el-table-column label="申报表附件" width="120" header-align="center" align="center">-->
      <!--<template slot-scope="scope">-->
      <!--<template v-if="$zwxBase.verifyIsBlank(scope.row.hazardDeclarationAnnexList)">-->
      <!--<div class="zwx-folder-content">-->
      <!--<i class="zwx-folder-content-icon"></i>-->
      <!--<span class="zwx-folder-content-num">0</span>-->
      <!--</div>-->
      <!--</template>-->
      <!--<template v-else>-->
      <!--<el-dropdown placement="bottom" trigger="click">-->
      <!--<div class="zwx-folder-content">-->
      <!--<i class="zwx-folder-content-icon"></i>-->
      <!--<span class="zwx-folder-content-num">{{ scope.row.hazardDeclarationAnnexList.length }}</span>-->
      <!--</div>-->
      <!--<el-dropdown-menu slot="dropdown">-->
      <!--<el-dropdown-item v-for="(item, index) in scope.row.hazardDeclarationAnnexList" :key="index" @click.native="openFilePreview(scope.row.hazardDeclarationAnnexList, index)">-->
      <!--<div>{{ item.fileName }}</div>-->
      <!--</el-dropdown-item>-->
      <!--</el-dropdown-menu>-->
      <!--</el-dropdown>-->
      <!--</template>-->
      <!--</template>-->
      <!--</el-table-column>-->
      <!--<el-table-column label="回执单" width="100" header-align="center" align="center">-->
      <!--<template slot-scope="scope">-->
      <!--<span v-if="$zwxBase.verifyIsNotBlank(scope.row.receiptPath)">-->
      <!--<el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview([{ fileName: scope.row.receiptNo, filePath: scope.row.receiptPath }], 0)">查看</el-button>-->
      <!--</span>-->
      <!--<span v-if="$zwxBase.verifyIsBlank(scope.row.receiptPath)">-</span>-->
      <!--</template>-->
      <!--</el-table-column>-->
      <!--<el-table-column prop="tdOhdApprovalRecordExp.approveUnitName" label="管理部门" width="200" header-align="center" align="left"></el-table-column>-->
      <el-table-column label="状态" width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-html="declareStatusFormatter(scope.row.declareStatus)"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" min-width="200">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.declareStatus == 0 || scope.row.declareStatus == 2" @click="edit(scope.row)">编辑</el-button>
          <!--          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-top-right" v-if="scope.row.declareStatus === 1" @click="showDeclarationConfirmDialog(scope.row)">确认申报</el-button>-->
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.declareStatus === 1 || scope.row.declareStatus == 3" @click="viewDetail(scope.row)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.declareStatus == 3 && $zwxBase.verifyIsNotBlank(scope.row.receiptPath)" @click="openFilePreview([{ fileName: scope.row.unitName + '-回执单(' + scope.row.receiptNo + ')', filePath: scope.row.receiptPath }], 0)">查看回执单</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.declareStatus != 0" @click="prints(scope.row)">打印申报表</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.declareStatus == 0" @click="del(scope.row)">删除</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="scope.row.declareStatus == 2 || scope.row.declareStatus == 3" @click="msLookAuditDetail(scope.row)">查看审核意见</el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="hazardDeclarationMainForm.currentPage" :pageSize="pageSize" :total="total" @currentChange="queryHazardDeclarationList" />

    <!--确认申报弹框-->
    <base-dialog class="declaration-confirm-dialog" ref="declarationConfirmDialog" title="申报信息" :pagination="false" @determine="declarationConfirmDialogDetermine" @cancel="cancelDeclarationConfirmDialog">
      <el-form class="zwx-form edit-form" key="declarationConfirmForm" ref="declarationConfirmForm" :rules="declarationConfirmDialog.declarationConfirmFormRules" :model="declarationConfirmDialog.declarationConfirmForm" label-position="right" @submit.native.prevent>
        <div class="edit-row">
          <el-form-item label-width="81px" label="申报日期：" prop="declareDate">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" :picker-options="$validate.noOverToday()" v-model="declarationConfirmDialog.declarationConfirmForm.declareDate" type="date" placeholder="请选择"></el-date-picker>
          </el-form-item>
          <el-form-item label-width="81px" label="管理部门：" prop="supervisonOrgan" style="margin-right: unset !important;">
            <el-input style="width:260px !important" class="zwx-input" v-model="declarationConfirmDialog.declarationConfirmForm.supervisonOrgan" placeholder="" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="81px" label="回执编号：" prop="receiptNo" style="margin-right: unset !important;">
            <el-input style="width:260px !important" class="zwx-input" v-model="declarationConfirmDialog.declarationConfirmForm.receiptNo" placeholder="" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="81px" label="回执单：" prop="receiptPath">
            <file-upload ref="msReceiptPositive" @showFileList="clickReceiptPositive" @success="clickReceiptPositiveSuccess" @error="clickError()" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" :size="5 * 1024 * 1024" sizeInfo="5M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(declarationConfirmDialog.declarationConfirmForm.receiptList)">上传</el-button>
            </file-upload>
            <div class="show-file" v-if="declarationConfirmDialog.declarationConfirmForm.receiptList.length > 0">
              <span v-for="(item, index) in declarationConfirmDialog.declarationConfirmForm.receiptList" :key="item.fileName" @mouseenter="mouseenterReceipt(index)" @mouseleave="mouseleaveReceipt(index)">
                <span>
                  <span style="margin-left:12px;">
                    <i :class="item.fileIconShow" style="color:#316CF5!important" />
                    {{ item.fileName }}
                  </span>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                    <i class="el-icon-upload-success el-icon-circle-check"></i>
                  </span>
                  <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                    <i class="el-icon-close" @click="deletionReceiptPositive(index)"></i>
                  </span>
                  <el-divider direction="vertical" v-if="declarationConfirmDialog.declarationConfirmForm.receiptList.length > 1 && item !== declarationConfirmDialog.declarationConfirmForm.receiptList[declarationConfirmDialog.declarationConfirmForm.receiptList.length - 1]"></el-divider>
                </span>
              </span>
            </div>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="81px" label="申报附件：" prop="hazardDeclarationAnnexList">
            <file-upload ref="msAnnexPositive" @showFileList="clickAnnexPositive" @success="clickAnnexPositiveSuccess" @error="clickError()" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" :size="5 * 1024 * 1024" sizeInfo="5M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList) || declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.length < 3">上传</el-button>
            </file-upload>
            <div class="show-file" v-if="declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.length > 0">
              <span v-for="(item, index) in declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList" :key="item.fileName" @mouseenter="mouseenterAnnex(index)" @mouseleave="mouseleaveAnnex(index)">
                <span>
                  <span style="margin-left:12px;">
                    <i :class="item.fileIconShow" style="color:#316CF5!important" />
                    {{ item.fileName }}
                  </span>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                    <i class="el-icon-upload-success el-icon-circle-check"></i>
                  </span>
                  <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                    <i class="el-icon-close" @click="deletionAnnexPositive(index)"></i>
                  </span>
                  <el-divider direction="vertical" v-if="declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.length > 1 && item !== declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.length - 1]"></el-divider>
                </span>
              </span>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>

    <!--审核意见-->
    <base-dialog class="audit-detail-dialog" ref="auditDetailDialog" title="审核意见" :pagination="false" :ifFooter="false" @cancel="cancelAuditDetailDialog">
      <el-card class="zwx-card" style="margin: 5px 5px;">
        <div class="zwx-login-dialog-content" align="center">
          <el-table class="zwx-table" style="border-left: 1px solid #e1e5f4 !important;" row-key="audit-table-key" :data="auditTableList" highlight-current-row stripe border>
            <el-table-column prop="approveResult" label="审核结果" width="130" header-align="center" align="center">
              <template slot-scope="scope">
                <span v-html="approveResultFormatter(scope.row.approveResult)"></span>
              </template>
            </el-table-column>
            <el-table-column prop="approveUnitName" label="审核机构" width="220" header-align="center" align="left"></el-table-column>
            <el-table-column prop="approveUser" label="审核人" width="140" header-align="center" align="center"></el-table-column>
            <el-table-column prop="approveDate" label="审核时间" width="110" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
            <el-table-column prop="approveRemark" label="审核意见" header-align="center" align="left" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="审核附件" width="90" header-align="center" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <template v-if="$zwxBase.verifyIsBlank(scope.row.approvePath)">
                  <span>—</span>
                </template>
                <template v-else>
                  <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="openFilePreview([{ fileName: '审核附件', filePath: scope.row.approvePath }], 0)">查看</el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </base-dialog>
    <print-health ref="printHealth" :msDeclarationPrintViewForm="msDeclarationPrintViewForm"></print-health>
  </div>
</template>

<script>
import PrintHealth from './components/PrintHealth.vue'
export default {
  name: 'HazardDeclaration',
  components: { PrintHealth },
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      employerUuid: '',
      businessUuid: '',
      declarYearList: [],
      ifAddHazardDeclaration: false,
      hazardDeclarationMainForm: {
        currentPage: 1, // 指定页码（必填）
        declareYear: '',
      },
      tableList: [],
      total: 0,
      pageSize: 18,
      declarationConfirmDialog: {
        deptList: [],
        declarationConfirmForm: {
          rid: '',
          declareDate: null,
          supervisonOrgan: '',
          receiptNo: '',
          receiptPath: '',
          receiptList: [],
          hazardDeclarationAnnexList: [],
        },
        declarationConfirmFormRules: {
          declareDate: [{ required: true, message: '请选择', trigger: 'change' }],
          supervisonOrgan: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
          receiptNo: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
          receiptPath: [{ required: true, message: '请上传', trigger: ['change', 'blur'] }],
          hazardDeclarationAnnexList: [{ required: true, message: '请上传', trigger: ['change', 'blur'] }],
        },
      },
      auditTableList: [],
      // 申报表部分
      msHazardDistributionForm: {},
      msDetectionForm: {},
      msSupervisionForm: {},
      msDeclarationPrintViewForm: {},
    }
  },
  created() {},
  mounted() {
    //综合预警跳转
    let type = window.localStorage.getItem('355fdbc223e9e2de&type')
    let businessUuid = window.localStorage.getItem('355fdbc223e9e2de&businessUuid')
    if (type != undefined && type != '' && businessUuid != undefined && businessUuid != '') {
      this.businessUuid = businessUuid
      if (type == 1) {
        //预警处置跳转
        this.getDeclarationRecord()
      } else if (type == 2) {
        //预警详情跳转
        this.$router.push({ name: 'HazardDeclarationView', params: { businessUuid: businessUuid } })
      }
      window.localStorage.setItem('355fdbc223e9e2de&type', '')
      window.localStorage.setItem('355fdbc223e9e2de&businessUuid', '')
    }
    if (this.$zwxBase.verifyIsNotBlank(this.$route.query.employerUuid)) {
      this.employerUuid = this.$route.query.employerUuid
    }
    this.getDeclareYearList()
  },
  activated() {
    if (this.$route.params.fresh) {
      this.getDeclareYearList()
    }
  },
  computed: {},
  methods: {
    /**
     * 获取年份下拉
     */
    getDeclareYearList() {
      var data = {
        employerUuid: this.employerUuid,
      }
      this.$system.get(
        this.api + '/oh/declaration/getHazardDeclarationYearList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.declarYearList = data.hazardDeclarationYearList
            this.hazardDeclarationMainForm.declareYear = data.maxDeclareYear
            this.queryHazardDeclarationList(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 查询申报记录列表
     */
    queryHazardDeclarationList(currentPage) {
      this.hazardDeclarationMainForm.currentPage = currentPage
      let data = {
        employerUuid: this.employerUuid,
        declareYear: this.hazardDeclarationMainForm.declareYear,
        currentPage: this.hazardDeclarationMainForm.currentPage,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/declaration/getOccupationalHazardDeclarationListSelf-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.tableList = data.resultList || []
            this.pageSize = data.pageSize
            this.total = data.resultCount
            this.ifAddHazardDeclaration = data.ifCanAdd
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 申报年份改变回调
     */
    declareYearChange() {
      this.queryHazardDeclarationList(1)
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    /**
     * 申报类型
     */
    declareTypeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue === 0) {
          return '初次申报'
        } else if (cellValue === 1) {
          return '变更申报'
        } else if (cellValue === 2) {
          return '年度申报'
        }
      }
    },
    declareStatusFormatter(declareStatus) {
      if (this.$zwxBase.verifyIsNotBlank(declareStatus)) {
        if (declareStatus == 0) {
          return '<span class="zwx-circle-text zwx-circle-underway">待上报</span>'
        }
        if (declareStatus == 1) {
          return '<span class="zwx-circle-text zwx-circle-normal">待审批</span>'
        }
        if (declareStatus == 2) {
          return '<span class="zwx-circle-text zwx-circle-finish">审批不通过</span>'
        }
        if (declareStatus == 3) {
          return '<span class="zwx-circle-text zwx-circle-complete">审批通过</span>'
        }
      }
      return '-'
    },
    /**
     * 文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 新增申报
     */
    addHazardDeclaration() {
      //type:1 新增
      this.$router.push({ name: 'HazardDeclarationEdit', params: { employerUuid: this.employerUuid, type: 1 } })
    },
    /**
     * 修改
     */
    edit(row) {
      //type:2 修改
      this.$router.push({ name: 'HazardDeclarationEdit', params: { rid: row.rid, informantStep: row.informantStep, employerUuid: this.employerUuid, type: 2 } })
    },
    /**
     * 查看
     */
    viewDetail(row) {
      this.$router.push({ name: 'HazardDeclarationView', params: { rid: row.rid } })
    },
    /**
     * 删除
     */
    del(row) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        let data = {
          rid: row.rid,
        }
        this.$emit('loading', true)
        this.$system.post(
          this.api + '/oh/declaration/deleteOccupationalHazardDeclaration-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$notify.success({ title: '成功', message: data.mess })
              this.queryHazardDeclarationList(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },
    msLookAuditDetail(row) {
      this.$emit('loading', true)
      let data = { fkByMainId: { rid: row.rid } }
      this.$system.postJson(this.api + '/oh/declaration/getOhdApprovalRecordListByMainId-1', data, true, true, this.getOhdApprovalRecordSuccess, this.error)
    },
    getOhdApprovalRecordSuccess(data) {
      if (data.type === '00') {
        this.auditTableList = data.ohdApprovalRecordList || {}
        this.$refs.auditDetailDialog.show(true)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    cancelAuditDetailDialog() {
      this.$refs.auditDetailDialog.show(false)
    },
    approveResultFormatter(declareStatus) {
      if (this.$zwxBase.verifyIsNotBlank(declareStatus)) {
        if (declareStatus == 0) {
          return '<span class="zwx-circle-text zwx-circle-finish">审批不通过</span>'
        }
        if (declareStatus == 1) {
          return '<span class="zwx-circle-text zwx-circle-complete">审批通过</span>'
        }
      }
      return '-'
    },
    /**
     * 确认申报
     */
    showDeclarationConfirmDialog(row) {
      this.$refs.declarationConfirmDialog.show(true)
      this.declarationConfirmDialog.declarationConfirmForm.rid = row.rid
    },
    dealfilePrefix(file) {
      let prefix = file.substring(file.lastIndexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'zwx-file-pdf-icon'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'zwx-file-word-icon'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'zwx-file-jpg-icon'
      }
      return fileIconShow
    },
    // 上传文件的UID
    generateFilesUid(flag) {
      this.$emit('loading', true)
      let data = {
        folder: '/hazard_declaration',
      }
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            if (flag === 1) {
              this.$refs.msReceiptPositive.submit(data.annexUploadUid)
            } else if (flag === 2) {
              this.$refs.msAnnexPositive.submit(data.annexUploadUid)
            }
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    clickError() {},
    /**
     * 上传回执单
     */
    clickReceiptPositive(showList) {
      showList.forEach(item => {
        let flag = this.declarationConfirmDialog.declarationConfirmForm.receiptList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.declarationConfirmDialog.declarationConfirmForm.receiptList.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid(1)
    },
    /**
     * 上传回执单成功回调
     */
    clickReceiptPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.declarationConfirmDialog.declarationConfirmForm.receiptList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.declarationConfirmDialog.declarationConfirmForm.receiptList) {
          for (let j in fileList) {
            if (this.declarationConfirmDialog.declarationConfirmForm.receiptList[i].fileName === fileList[j].fileName) {
              this.declarationConfirmDialog.declarationConfirmForm.receiptList[i].filePath = fileList[j].filePath
            }
          }
        }
        this.declarationConfirmDialog.declarationConfirmForm.receiptPath = this.declarationConfirmDialog.declarationConfirmForm.receiptList[0].filePath
        if (this.declarationConfirmDialog.declarationConfirmForm.receiptList != []) {
          this.$refs.declarationConfirmForm.validateField('receiptPath', valid => {
            if (!valid) return
          })
        }
      }
    },
    mouseenterReceipt(index) {
      this.declarationConfirmDialog.declarationConfirmForm.receiptList[index].check = true
    },
    mouseleaveReceipt(index) {
      this.declarationConfirmDialog.declarationConfirmForm.receiptList[index].check = false
    },
    // 删除文件
    deletionReceiptPositive(index) {
      let fileName = this.declarationConfirmDialog.declarationConfirmForm.receiptList[index].fileName
      let filePath = this.declarationConfirmDialog.declarationConfirmForm.receiptList[index].filePath
      this.declarationConfirmDialog.declarationConfirmForm.receiptList.splice(index, 1)
      this.declarationConfirmDialog.declarationConfirmForm.receiptPath = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.msReceiptPositive.deleteIndex(index)
      }
      let fileList = this.$refs.msReceiptPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msReceiptPositive.deleteIndex(j)
            fileList = this.$refs.msReceiptPositive.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 上传申报附件
     */
    clickAnnexPositive(showList) {
      showList.forEach(item => {
        let flag = this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid(2)
    },
    /**
     * 上传申报附件成功回调
     */
    clickAnnexPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList) {
          for (let j in fileList) {
            if (this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[i].fileName === fileList[j].fileName) {
              this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[i].filePath = fileList[j].filePath
            }
          }
        }
        this.$refs.declarationConfirmForm.validateField('hazardDeclarationAnnexList', valid => {
          if (!valid) return
        })
      }
    },
    mouseenterAnnex(index) {
      this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[index].check = true
    },
    mouseleaveAnnex(index) {
      this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[index].check = false
    },
    // 删除申报附件文件
    deletionAnnexPositive(index) {
      let fileName = this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[index].fileName
      let filePath = this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList[index].filePath
      this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.msAnnexPositive.deleteIndex(index)
      }
      let fileList = this.$refs.msAnnexPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msAnnexPositive.deleteIndex(j)
            fileList = this.$refs.msAnnexPositive.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 申报确认弹框提交按钮回调
     */
    declarationConfirmDialogDetermine() {
      this.$refs.declarationConfirmForm.validate(valid => {
        if (valid) {
          // if (this.$zwxBase.verifyIsBlank(this.declarationConfirmDialog.declarationConfirmForm.receiptList)) {
          //   this.$notify.info({ title: '提示', message: '请上传回执单' })
          //   return
          // }
          // if (this.$zwxBase.verifyIsBlank(this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList)) {
          //   this.$notify.info({ title: '提示', message: '请上传申报附件' })
          //   return
          // }
          this.saveDeclarationConfirm()
        }
      })
    },
    /**
     * 申报确认弹框提交按钮回调
     */
    saveDeclarationConfirm() {
      let data = this.declarationConfirmDialog.declarationConfirmForm
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/declaration/completeHazardDeclaration-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$notify.success({ title: '成功', message: data.mess })
            this.$refs.declarationConfirmDialog.show(false)
            this.resetDeclarationConfirmDialog()
            this.queryHazardDeclarationList(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 申报确认弹框取消按钮回调
     */
    cancelDeclarationConfirmDialog() {
      this.$refs.declarationConfirmDialog.show(false)
      this.resetDeclarationConfirmDialog()
    },
    /**
     * 申报确认弹框重置
     */
    resetDeclarationConfirmDialog() {
      this.declarationConfirmDialog.deptList = []
      this.declarationConfirmDialog.declarationConfirmForm.declareDate = null
      this.declarationConfirmDialog.declarationConfirmForm.supervisonOrgan = ''
      this.declarationConfirmDialog.declarationConfirmForm.receiptNo = ''
      this.declarationConfirmDialog.declarationConfirmForm.receiptPath = ''
      this.declarationConfirmDialog.declarationConfirmForm.receiptList = []
      this.declarationConfirmDialog.declarationConfirmForm.hazardDeclarationAnnexList = []
    },
    /**
     * 打印申报表
     */
    prints(row) {
      let data = { rid: row.rid }
      this.$parent.loading = true
      this.$system.get(
        this.api + '/oh/declaration/getDeclarationPrintInit-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            try {
              this.msForm = data.tdOccupationalHazardDeclarationExp
              let hazardDistributionDetail = this.msForm.tdHazardDistributionDetailExpList
              this.msHazardDistributionForm.dustList = []
              this.msHazardDistributionForm.chemicalList = []
              this.msHazardDistributionForm.physicalList = []
              this.msHazardDistributionForm.radioactivityList = []
              this.msHazardDistributionForm.bioticList = []
              this.msHazardDistributionForm.otherList = []
              this.msDetectionForm.bioticOtherList = []
              this.msSupervisionForm.bioticOtherList = []
              if (hazardDistributionDetail != null && hazardDistributionDetail.length > 0) {
                for (var k in hazardDistributionDetail) {
                  if (hazardDistributionDetail[k].hazardsSort === 1) {
                    this.msHazardDistributionForm.dustList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 2) {
                    this.msHazardDistributionForm.chemicalList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 3) {
                    this.msHazardDistributionForm.physicalList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 4) {
                    this.msHazardDistributionForm.radioactivityList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 5) {
                    this.msHazardDistributionForm.bioticList.push(hazardDistributionDetail[k])
                    if (this.msHazardDistributionForm.existsBiotic) {
                      var hazard = {}
                      Object.assign(hazard, hazardDistributionDetail[k])
                      hazard.index = this.msDetectionForm.bioticOtherList.length - 1
                      this.msDetectionForm.bioticOtherList.push(hazard)
                      hazard.index = this.msSupervisionForm.bioticOtherList.length - 1
                      this.msSupervisionForm.bioticOtherList.push(hazard)
                    }
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 6) {
                    this.msHazardDistributionForm.otherList.push(hazardDistributionDetail[k])
                    if (this.msHazardDistributionForm.existsOther) {
                      var hazard = {}
                      Object.assign(hazard, hazardDistributionDetail[k])
                      hazard.index = this.msDetectionForm.bioticOtherList.length - 1
                      this.msDetectionForm.bioticOtherList.push(hazard)
                      hazard.index = this.msSupervisionForm.bioticOtherList.length - 1
                      this.msSupervisionForm.bioticOtherList.push(hazard)
                    }
                  }
                }
              }
              this.msDeclarationPrintForListPrint()
            } catch (error) {
              console.log(error, 'error')
            }
          } else if (data.type === '99') {
            // this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    msDeclarationPrintForListPrint() {
      this.dealBioticOtherHazard()
      var printData = {}
      printData.declarationExp = {
        rid: this.msForm.rid,
        totalTraineeRate: this.msForm.totalTraineeRate,
        unitName: this.msForm.unitName,
        enterpriseScale: this.msForm.enterpriseScale,
        industryCateCode: this.msForm.industryCode,
        ifClassifiedUnit: this.msForm.ifClassifiedUnit,
        workAddr: this.msForm.workplaceAddr,
        regAddr: this.msForm.registerAddr,
        legalPerson: this.msForm.legalPerson,
        registerZone: this.msForm.registerZone,
        legalPersonPhone: this.msForm.legalPersonPhone,
        creditCode: this.msForm.creditCode,
        declareStatus: this.msForm.declareStatus,
        declareDate: this.msForm.declareDate ? this.$system.formatDate(this.msForm.declareDate, 'YYYY-MM-DD') : '/',
        declareYear: this.msForm.declareYear,
        declareType: this.msForm.declareType,
        economicName: this.msForm.economicName,
        industryCateName: this.msForm.industryName,
        reasonNo: this.msForm.reasonCode,
        reasonName: this.msForm.reasonCodeName,
        remark: this.msForm.otherReason,
        linkManager: this.msForm.linkManager,
        linkPhone: this.msForm.linkManagerContact,
        fillMan: this.msForm.informant,
        fillPhone: this.msForm.informantContact,
        empNum: this.msForm.totalStaffNum,
        registerNum: this.msForm.registerNum,
        outSourcingNum: this.msForm.outsourcingNum,
        externalNum: this.msForm.totalSubcontractingStaff,
        victimsNum: this.msForm.totalVictimsNum,
        occupationalDiseasesNum: this.msForm.cumulativePatients,
        existsLeaderTrain: this.msForm.principalTraining,
        existsManagersTrain: this.msForm.managerTraining,
        trainSum: this.msForm.totalTrainee,
        ifWarProduct: false,
        distributionExp: this.msForm.tdHazardDistributionExp || {},
        detectionExp: this.msForm.tdHazardDetectionExp || {},
        supervisionExp: this.msForm.tdHealthSupervisionExp || {},
      }
      printData.dustDetailList = this.msHazardDistributionForm.dustList || []
      printData.chemicalDetailList = this.msHazardDistributionForm.chemicalList || []
      printData.physicalDetailList = this.msHazardDistributionForm.physicalList || []
      printData.radioActivityDetailList = this.msHazardDistributionForm.radioactivityList || []
      printData.bioticDetailList = this.msHazardDistributionForm.bioticList || []
      printData.otherDetailList = this.msHazardDistributionForm.otherList || []
      printData.majorProductList = this.msForm.tdMajorProductExpList || []
      // printData.annexExpList = this.msAnnexForm.msAnnexList || []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tdOhdApprovalRecordExp.approveDate)) {
        printData.approvalRecordExp = { approveDate: this.$system.formatDate(this.msForm.tdOhdApprovalRecordExp.approveDate, 'yyyy-MM-DD') }
      } else {
        printData.approvalRecordExp = {}
      }
      var detectOrgan = this.msForm.tdHazardDetectionRecordExpList
      var superOrgan = this.msForm.tdHealthSupervisionRecordExpList
      var delectionCode = ''
      var delectionOrganName = ''
      for (var i in detectOrgan) {
        delectionCode += '，' + detectOrgan[i].reportNo
        delectionOrganName += '，' + detectOrgan[i].unitName
      }
      printData.declarationExp.delectionCode = delectionCode.substring(1)
      printData.declarationExp.delectionOrganName = delectionOrganName.substring(1)
      var supervisionCode = ''
      var supervisionOrganName = ''
      for (var i in superOrgan) {
        supervisionCode += '，' + superOrgan[i].reportNo
        supervisionOrganName += '，' + superOrgan[i].unitName
      }
      printData.declarationExp.supervisionCode = supervisionCode.substring(1)
      printData.declarationExp.supervisionOrganName = supervisionOrganName.substring(1)
      this.msDeclarationDetailPrintInit(printData)
      this.$parent.loading = false
    },
    dealBioticOtherHazard() {
      var bioticOtherList = this.msDetectionForm.bioticOtherList
      var bioOtherMap = new Map()
      for (var i in bioticOtherList) {
        bioOtherMap.set(bioticOtherList[i].codeNo, bioticOtherList[i])
      }
      for (var i in this.msHazardDistributionForm.bioticList) {
        if (bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo) !== undefined) {
          this.msHazardDistributionForm.bioticList[i].checkPoints = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).checkPoints
          this.msHazardDistributionForm.bioticList[i].overproofPoints = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).overproofPoints
          this.msHazardDistributionForm.bioticList[i].peNum = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).peNum
        }
      }
      for (var i in this.msHazardDistributionForm.otherList) {
        if (bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo) !== undefined) {
          this.msHazardDistributionForm.otherList[i].checkPoints = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).checkPoints
          this.msHazardDistributionForm.otherList[i].overproofPoints = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).overproofPoints
          this.msHazardDistributionForm.otherList[i].peNum = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).peNum
        }
      }
    },
    msDeclarationDetailPrintInit(body) {
      this.msDeclarationPrintViewForm.declarationExp = body.declarationExp || {}
      this.msDeclarationPrintViewForm.distributionExp = body.declarationExp.distributionExp || {}
      this.msDeclarationPrintViewForm.detectionExp = body.declarationExp.detectionExp || {}
      this.msDeclarationPrintViewForm.supervisionExp = body.declarationExp.supervisionExp || {}
      this.msDeclarationPrintViewForm.dustDetailList = body.dustDetailList || []
      this.msDeclarationPrintViewForm.chemicalDetailList = body.chemicalDetailList || []
      this.msDeclarationPrintViewForm.physicalDetailList = body.physicalDetailList || []
      this.msDeclarationPrintViewForm.radioActivityDetailList = body.radioActivityDetailList || []
      this.msDeclarationPrintViewForm.bioticDetailList = body.bioticDetailList || []
      this.msDeclarationPrintViewForm.otherDetailList = body.otherDetailList || []
      this.msDeclarationPrintViewForm.majorProductList = body.majorProductList || []
      this.msDeclarationPrintViewForm.annexExpList = body.annexExpList || []
      this.msDeclarationPrintViewForm.approvalRecordExp = body.approvalRecordExp || {}
      this.$refs.printHealth.assignment(this.msDeclarationPrintViewForm)
      this.$nextTick(() => {
        this.$refs.printHealth.printApplyTable(this.msDeclarationPrintViewForm)
      })
    },

    getDeclarationRecord() {
      this.$emit('loading', true)
      let data = {}
      this.$system.postJson(this.api + '/oh/declaration/getNewDeclarationRecord-1', data, true, true, this.detailSuccess, this.error)
    },
    detailSuccess(data) {
      if (data.type === '00') {
        let declarationExp = data.declarationExp
        let uuid = data.declarationExp.uuid
        if (this.$zwxBase.verifyIsNotBlank(this.businessUuid) && this.$zwxBase.verifyIsNotBlank(uuid)) {
          if (this.businessUuid == uuid) {
            this.$router.push({ name: 'HazardDeclarationEdit', params: { employerUuid: this.employerUuid, type: 1 } })
          } else {
            if (declarationExp.declareStatus == 0 || declarationExp.declareStatus == 2) {
              this.$router.push({ name: 'HazardDeclarationEdit', params: { rid: declarationExp.rid, informantStep: declarationExp.informantStep, employerUuid: this.employerUuid, type: 2 } })
            } else {
              this.$router.push({ name: 'HazardDeclarationView', params: { businessUuid: uuid } })
            }
          }
        }
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped>
.harzard-declaration-list-page {
  /deep/ .zwx-circle-save {
    color: #ff911a !important;
  }

  /deep/ .zwx-circle-save:before {
    background-color: #ff911a !important;
  }

  /deep/ .zwx-circle-to-declare {
    color: #9719bb !important;
  }

  /deep/ .zwx-circle-to-declare:before {
    background-color: #9719bb !important;
  }

  /deep/ .zwx-circle-declared {
    color: #15b150 !important;
  }

  /deep/ .zwx-circle-declared:before {
    background-color: #15b150 !important;
  }

  .declaration-confirm-dialog /deep/ .el-dialog {
    min-width: 634px !important;
    width: 634px !important;
  }

  .audit-detail-dialog /deep/ .el-dialog {
    min-width: 1200px !important;
    width: 1200px !important;
  }
}
</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 70%;
}
</style>
