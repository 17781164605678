var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-page" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "课程名称：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.msForm.courseName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "courseName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.courseName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程种类：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.courseTypeArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "courseTypeArr", $$v)
                        },
                        expression: "msForm.courseTypeArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("标准课程")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("自定义课程")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程状态：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.ifEnableArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "ifEnableArr", $$v)
                        },
                        expression: "msForm.ifEnableArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("停用")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.query()
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid+'-only'",
            data: _vm.dataList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "courseName",
              label: "课程名称",
              "min-width": "300",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "courseType",
              label: "课程种类",
              width: "100",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.courseType == 1 ? "标准课程" : "自定义课程"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "traineeTypeName",
              label: "课程分类",
              width: "150",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.traineeTypeName) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trainingTypeName",
              label: "课程类型",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.trainingTypeName) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "requiredHour",
              label: "必要学时",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.requiredClassHour))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "requiredHour",
              label: "线上课程学时要求",
              width: "150",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.onlineRequiredClassHoure))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "requiredHour",
              label: "线下课程学时要求",
              width: "150",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.offLineTotalClassHour))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ifEnable",
              label: "课程状态",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "zwx-circle-text zwx-circle-normal"
                            },
                            [_vm._v("启用")]
                          )
                        ]
                      : _vm._e(),
                    !scope.row.ifEnable
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "zwx-circle-text zwx-circle-underway"
                            },
                            [_vm._v("停用")]
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "courseName",
              label: "创建时间",
              width: "170",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": 200,
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable === true
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.copyAdd(scope.row)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      : _vm._e(),
                    scope.row.courseType != 1 && scope.row.ifEnable === false
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.editTrainingCourse(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.courseType != 1 && scope.row.ifEnable === false
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.enableOrDisable(scope.row)
                              }
                            }
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e(),
                    scope.row.courseType != 1 && scope.row.ifEnable === true
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.enableOrDisable(scope.row)
                              }
                            }
                          },
                          [_vm._v("停用")]
                        )
                      : _vm._e(),
                    scope.row.ifEnable === false
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.$system.msgbox(
                                  scope.row,
                                  "提示",
                                  "确定要删除吗？",
                                  "确定",
                                  "取消",
                                  _vm.deleteTrainingCourse
                                )
                              }
                            }
                          },
                          [
                            _c("span", { staticStyle: { color: "#DD3838" } }, [
                              _vm._v("删除")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "normal-pagination",
        attrs: {
          parentPage: _vm.currentPage,
          pageSize: _vm.pageSize,
          total: _vm.total
        },
        on: { currentChange: _vm.currentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }