var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "harzard-declaration-list-page" },
    [
      _c(
        "el-form",
        {
          key: "hazardDeclarationMainForm",
          ref: "hazardDeclarationMainForm",
          staticClass: "zwx-form",
          attrs: {
            model: _vm.hazardDeclarationMainForm,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row self-adapting-condition" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申报年份：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        placeholder: "",
                        filterable: true,
                        clearable: true
                      },
                      on: { change: _vm.declareYearChange },
                      model: {
                        value: _vm.hazardDeclarationMainForm.declareYear,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.hazardDeclarationMainForm,
                            "declareYear",
                            $$v
                          )
                        },
                        expression: "hazardDeclarationMainForm.declareYear"
                      }
                    },
                    _vm._l(_vm.declarYearList, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "condition-button-row" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.addHazardDeclaration }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-table",
        {
          key: "hazardDeclarationMainTable",
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.tableList,
            stripe: "",
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "declareYear",
              label: "年份",
              width: "120",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "declareDate",
              label: "申报日期",
              width: "120",
              "header-align": "center",
              align: "center",
              formatter: _vm.dateFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "declareType",
              label: "申报类型",
              width: "150",
              "header-align": "center",
              align: "center",
              formatter: _vm.declareTypeFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "informant",
              label: "填报人",
              width: "120",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              width: "110",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.declareStatusFormatter(scope.row.declareStatus)
                        )
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.declareStatus == 0 || scope.row.declareStatus == 2
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    scope.row.declareStatus === 1 ||
                    scope.row.declareStatus == 3
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    scope.row.declareStatus == 3 &&
                    _vm.$zwxBase.verifyIsNotBlank(scope.row.receiptPath)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                _vm.openFilePreview(
                                  [
                                    {
                                      fileName:
                                        scope.row.unitName +
                                        "-回执单(" +
                                        scope.row.receiptNo +
                                        ")",
                                      filePath: scope.row.receiptPath
                                    }
                                  ],
                                  0
                                )
                              }
                            }
                          },
                          [_vm._v("查看回执单")]
                        )
                      : _vm._e(),
                    scope.row.declareStatus != 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.prints(scope.row)
                              }
                            }
                          },
                          [_vm._v("打印申报表")]
                        )
                      : _vm._e(),
                    scope.row.declareStatus == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.del(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    scope.row.declareStatus == 2 || scope.row.declareStatus == 3
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.msLookAuditDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看审核意见")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "normal-pagination",
        attrs: {
          parentPage: _vm.hazardDeclarationMainForm.currentPage,
          pageSize: _vm.pageSize,
          total: _vm.total
        },
        on: { currentChange: _vm.queryHazardDeclarationList }
      }),
      _c(
        "base-dialog",
        {
          ref: "declarationConfirmDialog",
          staticClass: "declaration-confirm-dialog",
          attrs: { title: "申报信息", pagination: false },
          on: {
            determine: _vm.declarationConfirmDialogDetermine,
            cancel: _vm.cancelDeclarationConfirmDialog
          }
        },
        [
          _c(
            "el-form",
            {
              key: "declarationConfirmForm",
              ref: "declarationConfirmForm",
              staticClass: "zwx-form edit-form",
              attrs: {
                rules: _vm.declarationConfirmDialog.declarationConfirmFormRules,
                model: _vm.declarationConfirmDialog.declarationConfirmForm,
                "label-position": "right"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "申报日期：",
                        prop: "declareDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "zwx-date-picker",
                        attrs: {
                          "popper-class": "zwx-date-picker-popper",
                          "picker-options": _vm.$validate.noOverToday(),
                          type: "date",
                          placeholder: "请选择"
                        },
                        model: {
                          value:
                            _vm.declarationConfirmDialog.declarationConfirmForm
                              .declareDate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.declarationConfirmDialog
                                .declarationConfirmForm,
                              "declareDate",
                              $$v
                            )
                          },
                          expression:
                            "declarationConfirmDialog.declarationConfirmForm.declareDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-right": "unset !important" },
                      attrs: {
                        "label-width": "81px",
                        label: "管理部门：",
                        prop: "supervisonOrgan"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "260px !important" },
                        attrs: {
                          placeholder: "",
                          clearable: "",
                          maxlength: "50"
                        },
                        model: {
                          value:
                            _vm.declarationConfirmDialog.declarationConfirmForm
                              .supervisonOrgan,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.declarationConfirmDialog
                                .declarationConfirmForm,
                              "supervisonOrgan",
                              $$v
                            )
                          },
                          expression:
                            "declarationConfirmDialog.declarationConfirmForm.supervisonOrgan"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-right": "unset !important" },
                      attrs: {
                        "label-width": "81px",
                        label: "回执编号：",
                        prop: "receiptNo"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "260px !important" },
                        attrs: {
                          placeholder: "",
                          clearable: "",
                          maxlength: "50"
                        },
                        model: {
                          value:
                            _vm.declarationConfirmDialog.declarationConfirmForm
                              .receiptNo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.declarationConfirmDialog
                                .declarationConfirmForm,
                              "receiptNo",
                              $$v
                            )
                          },
                          expression:
                            "declarationConfirmDialog.declarationConfirmForm.receiptNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "回执单：",
                        prop: "receiptPath"
                      }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "msReceiptPositive",
                          attrs: {
                            accept: ".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF",
                            size: 5 * 1024 * 1024,
                            sizeInfo: "5M"
                          },
                          on: {
                            showFileList: _vm.clickReceiptPositive,
                            success: _vm.clickReceiptPositiveSuccess,
                            error: function($event) {
                              return _vm.clickError()
                            }
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(
                            _vm.declarationConfirmDialog.declarationConfirmForm
                              .receiptList
                          )
                            ? _c(
                                "el-button",
                                {
                                  staticClass:
                                    "zwx-button zwx-button-icontext-28",
                                  attrs: { icon: "el-icon-upload2" }
                                },
                                [_vm._v("上传")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.declarationConfirmDialog.declarationConfirmForm
                        .receiptList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "show-file" },
                            _vm._l(
                              _vm.declarationConfirmDialog
                                .declarationConfirmForm.receiptList,
                              function(item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: item.fileName,
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.mouseenterReceipt(index)
                                      },
                                      mouseleave: function($event) {
                                        return _vm.mouseleaveReceipt(index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "12px"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              class: item.fileIconShow,
                                              staticStyle: {
                                                color: "#316CF5!important"
                                              }
                                            }),
                                            _vm._v(
                                              " " + _vm._s(item.fileName) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item check",
                                            style: item.check
                                              ? "margin-left:5px;display:none;"
                                              : "margin-left:5px;cursor:pointer"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-upload-success el-icon-circle-check"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "check",
                                            style: item.check
                                              ? "margin-left:5px;cursor:pointer"
                                              : "margin-left:5px;display:none;"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-close",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletionReceiptPositive(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm.declarationConfirmDialog
                                          .declarationConfirmForm.receiptList
                                          .length > 1 &&
                                        item !==
                                          _vm.declarationConfirmDialog
                                            .declarationConfirmForm.receiptList[
                                            _vm.declarationConfirmDialog
                                              .declarationConfirmForm
                                              .receiptList.length - 1
                                          ]
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "81px",
                        label: "申报附件：",
                        prop: "hazardDeclarationAnnexList"
                      }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "msAnnexPositive",
                          attrs: {
                            accept: ".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF",
                            size: 5 * 1024 * 1024,
                            sizeInfo: "5M"
                          },
                          on: {
                            showFileList: _vm.clickAnnexPositive,
                            success: _vm.clickAnnexPositiveSuccess,
                            error: function($event) {
                              return _vm.clickError()
                            }
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(
                            _vm.declarationConfirmDialog.declarationConfirmForm
                              .hazardDeclarationAnnexList
                          ) ||
                          _vm.declarationConfirmDialog.declarationConfirmForm
                            .hazardDeclarationAnnexList.length < 3
                            ? _c(
                                "el-button",
                                {
                                  staticClass:
                                    "zwx-button zwx-button-icontext-28",
                                  attrs: { icon: "el-icon-upload2" }
                                },
                                [_vm._v("上传")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.declarationConfirmDialog.declarationConfirmForm
                        .hazardDeclarationAnnexList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "show-file" },
                            _vm._l(
                              _vm.declarationConfirmDialog
                                .declarationConfirmForm
                                .hazardDeclarationAnnexList,
                              function(item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: item.fileName,
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.mouseenterAnnex(index)
                                      },
                                      mouseleave: function($event) {
                                        return _vm.mouseleaveAnnex(index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "12px"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              class: item.fileIconShow,
                                              staticStyle: {
                                                color: "#316CF5!important"
                                              }
                                            }),
                                            _vm._v(
                                              " " + _vm._s(item.fileName) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item check",
                                            style: item.check
                                              ? "margin-left:5px;display:none;"
                                              : "margin-left:5px;cursor:pointer"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-upload-success el-icon-circle-check"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "check",
                                            style: item.check
                                              ? "margin-left:5px;cursor:pointer"
                                              : "margin-left:5px;display:none;"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-close",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletionAnnexPositive(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm.declarationConfirmDialog
                                          .declarationConfirmForm
                                          .hazardDeclarationAnnexList.length >
                                          1 &&
                                        item !==
                                          _vm.declarationConfirmDialog
                                            .declarationConfirmForm
                                            .hazardDeclarationAnnexList[
                                            _vm.declarationConfirmDialog
                                              .declarationConfirmForm
                                              .hazardDeclarationAnnexList
                                              .length - 1
                                          ]
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "auditDetailDialog",
          staticClass: "audit-detail-dialog",
          attrs: { title: "审核意见", pagination: false, ifFooter: false },
          on: { cancel: _vm.cancelAuditDetailDialog }
        },
        [
          _c(
            "el-card",
            { staticClass: "zwx-card", staticStyle: { margin: "5px 5px" } },
            [
              _c(
                "div",
                {
                  staticClass: "zwx-login-dialog-content",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      staticStyle: {
                        "border-left": "1px solid #e1e5f4 !important"
                      },
                      attrs: {
                        "row-key": "audit-table-key",
                        data: _vm.auditTableList,
                        "highlight-current-row": "",
                        stripe: "",
                        border: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "approveResult",
                          label: "审核结果",
                          width: "130",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.approveResultFormatter(
                                        scope.row.approveResult
                                      )
                                    )
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "approveUnitName",
                          label: "审核机构",
                          width: "220",
                          "header-align": "center",
                          align: "left"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "approveUser",
                          label: "审核人",
                          width: "140",
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "approveDate",
                          label: "审核时间",
                          width: "110",
                          "header-align": "center",
                          align: "center",
                          formatter: _vm.dateFormatter
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "approveRemark",
                          label: "审核意见",
                          "header-align": "center",
                          align: "left",
                          "show-overflow-tooltip": true
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核附件",
                          width: "90",
                          "header-align": "center",
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm.$zwxBase.verifyIsBlank(
                                  scope.row.approvePath
                                )
                                  ? [_c("span", [_vm._v("—")])]
                                  : [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "zwx-button zwx-button-text-26",
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-arrow-right"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openFilePreview(
                                                [
                                                  {
                                                    fileName: "审核附件",
                                                    filePath:
                                                      scope.row.approvePath
                                                  }
                                                ],
                                                0
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("查看")]
                                      )
                                    ]
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("print-health", {
        ref: "printHealth",
        attrs: { msDeclarationPrintViewForm: _vm.msDeclarationPrintViewForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }